const modelsProperties = {
  Field: {
    title: '',
    helpText: '',
    placeholder: '',
    caption: '',
    options: []
  },
  ContentPackage: {
    name: '',
    tags: []
  },
  Document: {
    name: '',
    plainContent: '',
    content: ''
  },
  Tool: {
    name: ''
  },
  Location: {
    name: ''
  },
  Workflow: {
    name: '',
    tags: [],
    description: ''
  },
  Node: {
    title: '',
    description: ''
  },
  Chapter: {
    name: ''
  },
  Certificate: {
    name: ''
  },
  Category: {
    name: ''
  },
  Menus: {
    title: ''
  },
  List: {
    name: ''
  },
  ListItem: {
    name: '',
    content: ''
  },
  Unit: {
    name: ''
  }
}

module.exports = modelsProperties
