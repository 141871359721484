const getReadableFontColor = function getReadableFontColor (bgColor) {
  return '#000000'
  // // Convert the hex code to RGB
  // const r = parseInt(bgColor.substring(1, 3), 16)
  // const g = parseInt(bgColor.substring(3, 5), 16)
  // const b = parseInt(bgColor.substring(5, 7), 16)
  // // Get the luminance of the background color
  // const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255
  // // Choose between two shades of the background color
  // if (luminance > 0.5) {
  //   // For light backgrounds, choose a darker shade
  //   return getDarkerShade(bgColor)
  // } else {
  //   // For dark backgrounds, choose a lighter shade
  //   return getLighterShade(bgColor)
  // }
}
// Returns a lighter shade of the given color
// const getLighterShade = function getLighterShade (color) {
//   // Convert the hex code to RGB
//   const r = parseInt(color.substring(1, 3), 16)
//   const g = parseInt(color.substring(3, 5), 16)
//   const b = parseInt(color.substring(5, 7), 16)
//   // Lighten the color by 90%
//   const r2 = Math.round(r + (255 - r) * 0.9)
//   const g2 = Math.round(g + (255 - g) * 0.9)
//   const b2 = Math.round(b + (255 - b) * 0.9)
//   // Convert the RGB values back to a hex code
//   return (
//     '#' +
//     r2.toString(16).padStart(2, '0') +
//     g2.toString(16).padStart(2, '0') +
//     b2.toString(16).padStart(2, '0')
//   )
// }
// // Returns a darker shade of the given color
// const getDarkerShade = function getDarkerShade (color) {
//   // Convert the hex code to RGB
//   const r = parseInt(color.substring(1, 3), 16)
//   const g = parseInt(color.substring(3, 5), 16)
//   const b = parseInt(color.substring(5, 7), 16)
//   // Darken the color by 90%
//   const r2 = Math.round(r * 0.1)
//   const g2 = Math.round(g * 0.1)
//   const b2 = Math.round(b * 0.1)
//   // Convert the RGB values back to a hex code
//   return (
//     '#' +
//     r2.toString(16).padStart(2, '0') +
//     g2.toString(16).padStart(2, '0') +
//     b2.toString(16).padStart(2, '0')
//   )
// }

module.exports = {
  getReadableFontColor
}
