/**
 * Here you can add special handling to specific Model
 * there are 6 optional properties for each model - Import, Export, View, Edit, Filter and Relationships
 * those properties define the handling to translatable properties / relations inside those objects
 * @param {Object} Relationships Define to excel import/export functions which relationships need to be import/export too
 * @param {Object} Import Handle excel import properties AND relations
 * @param {Object} Export Handle excel export properties AND relations
 * @param {Object} View Handle properties before show in view mode
 * @param {Object} Edit Handle properties before show in edit mode
 * @param {Object} Filter where condition for queries
 */
const _ = require('lodash')

const modelsHandling = {
  Field: {
    Import: {
      options: (translationContent, fallBack, originalContent) => {
        const generatedOptions = []
        if (translationContent) {
          translationContent.split(',').forEach(option => {
            generatedOptions.push({
              value: option
            })
          })
        }
        return generatedOptions
      }
    },
    Export: {
      options: content => {
        return _.map(content, 'value').join()
      }
    },
    View: {
      options: (originalContent, translationContent) => {
        const options = []
        if (
          Array.isArray(originalContent) &&
          Array.isArray(translationContent)
        ) {
          originalContent.forEach((content, index) => {
            if (
              translationContent[index] &&
              translationContent[index].value &&
              translationContent[index].value !== ''
            ) {
              options.push({
                id: content.value,
                value: translationContent[index].value,
                resourceId: translationContent[index].resourceId || null
              })
            } else {
              options.push({
                id: content.value,
                value: content.value,
                resourceId: content.resourceId || null
              })
            }
          })
        }
        return options
      }
    },
    Edit: {
      options: (
        translationContent,
        originalContent,
        fallbackStatus,
        language
      ) => {
        if (
          (_.isUndefined(translationContent) &&
            !_.isUndefined(originalContent)) ||
          translationContent.length < originalContent.length ||
          (!_.isUndefined(fallbackStatus) &&
            !_.isUndefined(fallbackStatus[language]) &&
            !_.isUndefined(fallbackStatus[language].options) &&
            fallbackStatus[language].options)
        ) {
          if (_.isUndefined(translationContent)) {
            translationContent = []
          }
          originalContent.forEach(option => {
            if (
              translationContent.findIndex(op => op.id === option.id) === -1
            ) {
              translationContent.push({
                id: option.id,
                name: ''
              })
            }
          })
        }
        return translationContent
      }
    }
  },
  Workflow: {
    Filter: {
      where: {
        latestVersion: true
      }
    },
    Relationships: {
      Node: '_nodes',
      Chapter: '_chapters'
    },
    Import: {
      Node: 'workflowId'
    },
    Export: {
      Node: 'workflowId'
    }
  },
  ContentPackage: {
    Relationships: {
      Document: 'documents'
    },
    Import: {},
    Export: {}
  },
  Document: {
    Filter: {
      where: {
        currentReleasedVersion: true
      }
    }
  }
}

module.exports = modelsHandling
