const debug = require('debug')('query-cache')
module.exports = class QueryCache {
  /**
   * Query Cache class for client and server
   */
  constructor (ttl, clearCycle = false) {
    this.cache = {}
    this.ttl = ttl
    if (clearCycle === false) {
      clearCycle = this.ttl
    }
    this.clearCycle = clearCycle
    this.clearCache(clearCycle)
  }

  /**
   * @param {string} model
   * @param {string} functionName
   * @param {object} param
   */
  isCacheExpired (cacheName, param) {
    return (
      this.cache[cacheName][param] &&
      this.cache[cacheName][param].fetchDate < new Date().getTime()
    )
  }

  /**
   * @param {string} model model name
   * @param {string} functionName function name
   * @param {object} params query params
   * @param {function} fetchFunction function that return promise
   * @param {number} newTTL
   */
  getData (cacheName, params, fetchFunction, newTTL = null) {
    const paramsAsString = JSON.stringify(params)
    if (
      !this.cache[cacheName] ||
      !this.cache[cacheName][paramsAsString] ||
      this.isCacheExpired(cacheName, paramsAsString)
    ) {
      debug(`${cacheName} expired / not exists - fetching new data`)

      if (!this.cache[cacheName]) {
        this.cache[cacheName] = {}
      }
      const now = new Date()
      let fetchDate = now.getTime()
      if (newTTL) {
        fetchDate += newTTL
      } else {
        fetchDate += this.ttl
      }
      this.cache[cacheName][paramsAsString] = {
        data: fetchFunction(),
        fetchDate: fetchDate
      }
      return this.cache[cacheName][paramsAsString].data
    } else {
      debug('cache hit')
      return Promise.resolve(this.cache[cacheName][paramsAsString].data)
    }
  }

  /**
   * Clear old cache interval
   * @param {number} clearCycle
   */
  clearCache (clearCycle) {
    setInterval(() => {
      const cacheNames = Object.keys(this.cache)
      for (let i = 0; i < cacheNames.length; i++) {
        const cacheName = cacheNames[i]
        const params = Object.keys(this.cache[cacheName])
        for (let j = 0; j < params.length; j++) {
          const param = params[j]
          if (
            this.cache[cacheName][param] &&
            this.cache[cacheName][param].fetchDate
          ) {
            if (this.isCacheExpired(cacheName, param)) {
              delete this.cache[cacheName][param]
            }
          } else {
            delete this.cache[cacheName][param]
          }
        }
      }
    }, clearCycle)
  }
}
